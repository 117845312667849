@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Orbitron:wght@400..900&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;
/* html {
}
* {
} */
body {
  background-color: #f5f6f6;
  margin: 0;
  padding: 0;
  height: fit-content;
  font-family: "Inter", sans-serif;
}
